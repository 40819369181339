import './scss/index.scss';

import '../../vendor/back-to-top';
import '../../../../resources/assets/modules/shared/components/sliding-panel/jquery.sliding-panel';
import '../../../../resources/assets/vendor/jquery-validatioin-1.19.3/jquery.validate';
import {App} from "./app";
import {isSiteOnline, guid, url} from "../../shared/utils";
import '../../vendor/url-search-params';
import './framework';

import '../../vendor/jquery.jScale.js';
import '../../vendor/resizeandcrop/jquery.resizeandcrop';
import {ColorMode} from "../shared/components/color-mode/color-mode";
import {GoogleTranslateExt} from "../../shared/js/GoogleTranslateExt";

window.docPath = (function() {

    var exports = {};

    exports.page = document.location.pathname,
    exports.urlParams = new URLSearchParams(window.location.search),
    exports.nextParamSeparator = (window.location.href.indexOf("?")===-1)?"?":"&",
    exports.qStringVars = function (){
        return this.urlParams.toString();
    }
    exports.fullDocPath = function (){
        var rtn = '';
        if(this.qStringVars().length > 0){
            rtn = this.page + '?' + this.qStringVars();
        } else {
            rtn = this.page;
        }

        return rtn;
    }
    exports.removeFolder = function(value) {
        var urlParts = this.page.split('/');
        var indexToRemove = -1;
        urlParts.forEach((f,i)=>{
            if(f === value) {
                indexToRemove = i;
            }
        });
        if(indexToRemove > -1) {
            urlParts.splice(indexToRemove, 1);
            this.page = urlParts.join('/');
        }
    }
    exports.removeFolderAndParam = function(p) {

        var value = this.urlParams.get(p);
        this.removeFolder(value);
        this.urlParams.delete(p);
    }
    exports.removeQStringParam = function(p) {
        this.urlParams.delete(p);
    }
    exports.fullDocPathWithFolder = function(s){
        var str = this.page + '';
        var lastChar = str.substring(str.length - 1);
        var rtn = '';
        if(lastChar === '/') {
            rtn = this.page + s;
        } else {
            rtn = this.page + '/'+s;
        }

        if(this.qStringVars().length > 0){
            rtn = rtn + '?' + this.qStringVars();
        }

        return rtn;
    }
    exports.getNextSparatorInObj = function (){
        var rtn = "&";
        var dp = this.fullDocPath();
        var pdh = dp.indexOf("?");
        if(pdh===-1){
            rtn = "?";
        }
        return rtn;
    }

    return exports;
})();

window.InstantSearch = {

    "highlight": function (container, highlightText)
    {
        var internalHighlighter = function (options)
            {

                var id = {
                        container: "container",
                        tokens: "tokens",
                        all: "all",
                        token: "token",
                        className: "className",
                        sensitiveSearch: "sensitiveSearch"
                    },
                    tokens = options[id.tokens],
                    allClassName = options[id.all][id.className],
                    allSensitiveSearch = options[id.all][id.sensitiveSearch];


                function checkAndReplace(node, tokenArr, classNameAll, sensitiveSearchAll)
                {
                    //console.log(tokenArr);
                    var nodeVal = node.nodeValue, parentNode = node.parentNode,
                        i, j, curToken, myToken, myClassName, mySensitiveSearch,
                        finalClassName, finalSensitiveSearch,
                        foundIndex, begin, matched, end,
                        textNode, span, isFirst;

                    for (i = 0, j = tokenArr.length; i < j; i++)
                    {
                        curToken = tokenArr[i];
                        myToken = curToken[id.token];
                        myClassName = curToken[id.className];
                        mySensitiveSearch = curToken[id.sensitiveSearch];

                        finalClassName = (classNameAll ? myClassName + " " + classNameAll : myClassName);

                        finalSensitiveSearch = (typeof sensitiveSearchAll !== "undefined" ? sensitiveSearchAll : mySensitiveSearch);

                        isFirst = true;
                        while (true)
                        {
                            if (finalSensitiveSearch)
                                foundIndex = nodeVal.indexOf(myToken);
                            else {
                                foundIndex = nodeVal.toLowerCase().indexOf(myToken.toLowerCase());
                            }


                            if (foundIndex < 0)
                            {
                                if (isFirst)
                                    break;

                                if (nodeVal)
                                {
                                    textNode = document.createTextNode(nodeVal);
                                    parentNode.insertBefore(textNode, node);
                                } // End if (nodeVal)

                                parentNode.removeChild(node);
                                break;
                            } // End if (foundIndex < 0)


                            begin = nodeVal.substring(0, foundIndex);
                            matched = nodeVal.substr(foundIndex, myToken.length);

                            if (begin) {

                                if(isFirst) {
                                    begin = '...'+nodeVal.substring(foundIndex - 10, foundIndex);
                                }

                                textNode = document.createTextNode(begin);
                                parentNode.insertBefore(textNode, node);
                            } // End if (begin)

                            span = document.createElement("span");
                            span.className += finalClassName;
                            span.appendChild(document.createTextNode(matched));
                            parentNode.insertBefore(span, node);

                            nodeVal = nodeVal.substring(foundIndex + myToken.length);

                            isFirst = false;
                        } // Whend

                    } // Next i
                }; // End Function checkAndReplace

                function iterator(p)
                {
                    if (p === null) return;

                    var children = Array.prototype.slice.call(p.childNodes), i, cur;

                    if (children.length)
                    {
                        for (i = 0; i < children.length; i++)
                        {
                            cur = children[i];
                            if (cur.nodeType === 3)
                            {
                                checkAndReplace(cur, tokens, allClassName, allSensitiveSearch);
                            }
                            else if (cur.nodeType === 1)
                            {
                                iterator(cur);
                            }
                        }
                    }
                }; // End Function iterator

                iterator(options[id.container]);
            } // End Function highlighter
        ;


        internalHighlighter(
            {
                container: container
                , all:
                    {
                        className: "highlighter"
                    }
                , tokens: [
                    {
                        token: highlightText
                        , className: "tl-highlighted-light-without-padding"
                        , sensitiveSearch: false
                    }
                ]
            }
        ); // End Call internalHighlighter

    } // End Function highlight

};

window.tradeloop = (function () {

    let exports = {
        escape_queue: [],
        scroll_locked: false,
        cfMembersSite: 'https://members.' + this_site_domain,
        pageCTA: null,
        recaptchaWidgId: null,
        captchaResponse: '',
        rcPubK: '6Le1JngUAAAAAFbhvO0lxOTI3VNIDqOOAPypNiJ0',
    };

    exports.init = function () {

        $('.close').click(function(){
            var id = $(this).data('id');
            $('#'+id).remove();
        });

        if(typeof load_action != 'undefined') {
            var useCTA = void 0;
            var useUppathModal = void 0;

            if(typeof prevCTA != 'undefined') {
                useCTA = prevCTA;
            }

            if(typeof pgCTA != 'undefined') {
                useCTA = pgCTA;
            }

            if(typeof carryCTA !== 'undefined') {
                tradeloop.pageCTA = carryCTA;
            }

            switch(load_action){
                case 'public_content_gate_first_login':
                case 'public_content_gate_members_login':
                case 'public_register_confirmation_message':
                    useUppathModal = {
                        name: load_action,
                        cta: tradeloop.replaceUnderscoreToDash(load_action),
                        onLoad: null
                    };
                    break;

                case 'www_content_gate_members_only_listing':
                case 'public_content_gate_members_only_listing':
                    useUppathModal = {
                        name: 'members_only_listing',
                        cta: tradeloop.replaceUnderscoreToDash(load_action),
                        onLoad: null
                    };
                    break;

                case 'public_content_gate_members_only_page':
                    useUppathModal = {
                        name: 'members_only_feature',
                        cta: tradeloop.addCtaToLoadAction(load_action, useCTA),
                        onLoad: null
                    };
                    break;

                case 'www_content_gate_first_click_free':
                    useUppathModal = {
                        name: 'first_click_free',
                        cta: tradeloop.addCtaToLoadAction(load_action, useCTA),
                        onLoad: null
                    };
                    break;

                default:
                    if(load_action == 'register' || load_action == 'register-v1' || typeof useCTA !== 'undefined') {
                        tradeloop.modal(load_action, null, {cta: useCTA});
                    } else {
                        tradeloop.modal(load_action);
                    }
                    break;
            }


            if(typeof useUppathModal != 'undefined') {

                //console.log(useUppathModal);
                tradeloop.doModalUppathOpen(useUppathModal);
            }
        }

        $('.date-toggle')
            .mouseout(function() {
                var disp_date = $(this).data('lapse');
                $(this).html(disp_date);
            })
            .mouseover(function() {
                    var disp_date = $(this).data('date');
                    $(this).html(disp_date);
                }
            );
    };

    exports.addCtaToLoadAction = function(la, cta) {
        let str = this.replaceUnderscoreToDash(la);

        if(typeof cta !== 'undefined'){
            str = str + '/' + cta;
        }

        return str.toString().toLowerCase();
    };

    exports.replaceUnderscoreToDash = function(str) {
        let regex = /_/g;
        return str.toString().toLowerCase().replace(regex, "-");
    };

    exports.doModalUppathOpen = function(wd) {
        var data = {};

        if (typeof wd.name == 'undefined') {
            wd.name = 'bifurcating';
        }

        if (typeof wd.onLoad !== 'function') {
            wd.onLoad = null;
        }

        if (typeof wd.cta !== 'undefined') {
            data.cta = 'modal/' + wd.cta;

            if(typeof wd.link_action !== 'undefined'){
                data.uppath = data.cta + '/' + wd.link_action;
            }

        } else {
            if(typeof wd.link_action !== 'undefined'){
                data.uppath = wd.link_action;
            }
        }

        // anything extra - should be an obj
        if (typeof wd.input !== 'undefined') {
            $.extend(data, wd.input);
        }

        //console.debug(wd);
        //console.debug(data);

        tradeloop.modal('gates/'+wd.name, wd.onLoad, data);
    };

    exports.pluralize = function(cnt,s,p){
        if (cnt > 1 || cnt == 0) {
            return p;
        } else {
            return s;
        }
    }

    exports.sendScriptToHead = function(srcPath){
        var hasScript = document.querySelector('script[src="'+srcPath+'"]');

        if(hasScript == null) {
            var reCapScript = document.createElement("script");
            reCapScript.type = "text/javascript";
            reCapScript.src = srcPath;

            document.getElementsByTagName("head")[0].appendChild(reCapScript);
            //console.debug('RECAPCHA: added script to head');
        } else {
            //console.debug('RECAPCHA: script aleady on head');
        }
    }

    exports.loadReCapsToPage = function(id){
        tradeloop.recaptchaWidgId = grecaptcha.render(id, {
            sitekey : tradeloop.rcPubK,
            theme : "light",
            'callback': tradeloop.recaptchaCallback
        });
        //console.debug('RECAPCHA: doing grecaptcha.render');
    }

    exports.recaptchaCallback = function () { // initializing callback function
        tradeloop.captchaResponse = grecaptcha.getResponse();
    };

    exports.historyBackWFallbackUrl = function(fallbackUrl, cnt){
        fallbackUrl = fallbackUrl || '/';
        cnt = parseInt(cnt) || -1;
        var prevPage = window.location.href;

        window.history.go(cnt);

        setTimeout(function(){
            if (window.location.href == prevPage) {
                window.location.href = fallbackUrl;
            }
        }, 500);
    }

    exports.incrementHistoryOnElm = function(elm, dataAtt){
        var c = parseInt($(elm).data(dataAtt));
        var b = c - 1;
        $(elm).data(dataAtt, b);
    }

    exports.tradesUrl = function(id,uid,part_number,cat_tree,part_clean){
        //var rtn = '/trades/single?id=' + id;
        var rtn = '/' + 'inv' + cat_tree.toLowerCase() + '/' + part_clean.toLowerCase() + '?id=' + id;

        if(typeof _show_member_listings !== 'undefined' && _show_member_listings) {
            rtn = tradeloop.tradesSearchUrl(part_number,part_clean);
        }
        return rtn;
    }

    exports.catalogUrl = function(id,uid,part_number,cat_tree,part_clean){
        //var rtn = '/trades/single?id=' + id;
        var rtn = '/' + 'p' + cat_tree + '/' + part_clean + '?catalogid=' + id;

        if(typeof _show_member_listings !== 'undefined' && _show_member_listings) {
            rtn = tradeloop.tradesSearchUrl(part_number,part_clean);
        }
        return rtn;
    }

    exports.tradesSearchUrl = function(part_number,part_clean, mid){

        var manu_id = mid || null;

        var rtn = '/p/'+part_clean+'?k='+part_clean;

        if(manu_id !== null) {
            rtn += '&m='+manu_id;
        }


        if(typeof _show_member_listings !== 'undefined' && _show_member_listings) {
            rtn = tradeloop.cfMembersSite + '/computer_parts/members/?searchbox=MLAFS&k=' + part_number;
        }
        return rtn;
    }

    exports.messageCenterUrl = function(tfid,reply_cnt){
        var use_member_version = false;
        var rtn = '/messages';

        if(typeof _show_member_listings !== 'undefined' && _show_member_listings) {
            use_member_version = true;
            rtn = tradeloop.cfMembersSite + '/message-center/';
        }

        if(reply_cnt == 1){
            if(use_member_version){
                rtn = rtn + 'message/?ogtoid=' + tfid;
            } else {
                rtn = rtn + '/single?ogtoid=' + tfid;
            }
        }

        return rtn;
    }


    exports.inquiriesUrl = function(tfid, reply_cnt) {
        reply_cnt = parseInt(reply_cnt) || 0;
        let rtn = '/transactions';

        if (!tfid || reply_cnt === 0) {
            return rtn;
        }

        if (reply_cnt === 1) {
            if (typeof _show_member_listings !== 'undefined' && _show_member_listings) {
                rtn = tradeloop.cfMembersSite + '/message-center/message/?ogtoid=' + tfid;
            } else {
                rtn = '/messages/single?ogtoid=' + tfid;
            }
        } else {
            rtn = '/transactions?listingid=' + tfid + '&sort=status&sort_ord=asc&show_all=yes';
        }

        return rtn;
    }


    exports.messageReplyUrl = function(id){
        var rtn = '/messages/single?messageid=' + id;
        if(typeof _show_member_listings !== 'undefined' && _show_member_listings) {
            rtn = tradeloop.cfMembersSite + '/message-center/message/?id=' + id;
        }
        return rtn;
    }

    exports.emailSubscriptions = function(){
        var rtn = '/account/email-subscriptions';
        if(typeof _show_member_listings !== 'undefined' && _show_member_listings) {
            rtn = tradeloop.cfMembersSite + '/broadcast/settings/';
        }
        return rtn;
    }

    exports.toggleMeEdits = function(tp){
        var topOfPage = $(".main-body").offset().top -1;
        $('html, body').animate({scrollTop:topOfPage}, 200);

        switch(tp){
            case 'show':
            case 'show_full_modal':
                $('#verifing-info').addClass('hidden');
                $('#edit-page-body').removeClass('hidden');
                if(tp == 'show_full_modal'){
                    $('.modal-header').removeClass('hidden');
                }
                break;

            case 'hide':
            case 'hide_full_modal':
                $('#verifing-info').removeClass('hidden');
                $('#edit-page-body').addClass('hidden');
                if(tp == 'hide_full_modal'){
                    $('.modal-header').addClass('hidden');
                }
                break;
        }
    }

    exports.doNextMsgPagePath = function(msg, extras){
        var alsoAdd = extras || null;
        var dptp = docPath;
        dptp.urlParams.set('msg', msg);
        var rtn = dptp.fullDocPath();

        if(alsoAdd !== null) {
            rtn += alsoAdd;
        }

        return rtn;
    }

    exports.toggleSimpleId = function(id){
        $(id).slideToggle();
    }

    exports.resetSelectElement = function(elm){
        $(elm).prop('selectedIndex', 0)
    }

    exports.getModalNameForInit = function(str){
        var useFunction = '';
        var modalInit = str;
        modalInit = modalInit.replace(new RegExp('/', 'g'), '_');
        modalInit = modalInit.replace(new RegExp('-', 'g'), '_');

        if(modalInit.includes("free_trial")){ // can be something like free_trial_mobile
            modalInit = 'free_trial';
        }
        
        useFunction = 'init_' + modalInit;

        //console.debug('INIT:'+useFunction);

        return useFunction;
    };

    /**
     * SIMPLE CLOSE MODAL
     * useful if we have a big button to close a modal but we don't want to use the main close event for it
     */
    exports.doSimpleCloseModal = function(event, onLoad){
        $(".modal").remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open'); // For scroll run
        tradeloop.scrollUnlock('md-close-unlock-50000'); 
        
        if (typeof onLoad == 'function') {
            onLoad();
        }
    };

    /**
     * OPEN MODALS
     */
    exports.modal = function(id, onLoad, prop, cta, k){
        var data = {
            "container":"modal-container",
            "x-sender":"js"
        };
        var id = arguments[0];
        var onLoad = arguments[1];
        var prop = arguments[2];
        var cta = arguments[3];
        var modalName = id;
        var modalNameForInit = tradeloop.getModalNameForInit(id);
        var ctaKey = k||'cta';
        var modalPath = url('modal/'+id);

        //console.debug('MODAL FUNCTIONS:'+modalNameForInit);

        /**
         * absolute modal path overwite
         */
        if (prop && typeof prop['usingModalPath'] !== 'undefined') {
            modalPath = prop['usingModalPath'];
            delete prop['usingModalPath'];
        }

        if(arguments.length == 2){
            cta = arguments[1];
        }

        if (typeof cta == 'function') {
            cta = void 0;
        }

        if (prop && typeof prop['cta'] !== 'undefined') {
            cta = prop['cta'];
        } else {
            if (prop && typeof prop['uppath'] !== 'undefined') {
                cta = prop['uppath'];
            }
        }

        if(typeof cta !== 'undefined' && cta) {
            tradeloop.pageCTA = cta;
        }

        if (typeof prop != 'undefined') {
            $.extend(data, prop);
        }

        if (typeof tlpxtoken != 'undefined') {
            data.tlpxtoken = tlpxtoken;
        }

        if(typeof redirect_uri != 'undefined' && redirect_uri != '') {
            data.redirect_uri = redirect_uri;
        }

        data._token = $('input[name="_token"]:first').val();

        console.debug('MODAL LOADING: '+ modalPath);

        framework.modal(modalPath, data, function (r) {
            var modal_error_message = null;
            var mbtpath = null;
            var memLoginPath = tradeloop.cfMembersSite + '/member-login/?prev=php-login-modal/bottom-link/member-login';
            var vpHit = docPath.page + '?modal='+id+'/opened';
            var params = {
                modal: id
            }
            if(typeof redirect_uri != 'undefined' && redirect_uri != '') {
                mbtpath = redirect_uri;
                mbtpath = mbtpath.replace("//www.", "//members-beta.");
                mbtpath = mbtpath.replace("//public.", "//members-beta.");
                memLoginPath = memLoginPath + '&back2=' + mbtpath;
            }

            if (tradeloop.pageCTA !== null) {
                vpHit = vpHit + '&' + ctaKey + '=' + tradeloop.pageCTA;
                params['cta']  = ctaKey;
                params['ctaValue']  = tradeloop.pageCTA;
            }

            if(vpHit.indexOf('register') !== -1 && id !== 'register' && id !== 'confirmation-message') {
                params['funnel'] = 'register';
                vpHit = vpHit + '&funnel=register';
            }
            params.page = docPath.page;
            window.Analytics.trackInGA('OpenedModal', params);

            $('#modal-container').show();
            var effect = $('#modal-container').find('.modal:first').data('effect');
            if(effect == "blur") {
                $('.main-body').addClass('blur-in');
                $('#modal-container').find('.modal').show();
            } else {
                $('#modal-container').find('.modal').show();
            }

            if (!tradeloop.scroll_locked) {
                tradeloop.scrollLock('md-lock-10000');
            }

            if(typeof pgFCF != 'undefined') {
                $("button").remove(".close,.btn-done");
            }

            tradeloop.escape_queue.push(function () {
                $('.modal').find('.close').click();
                tradeloop.scrollUnlock('md-unlock-20000');
            });

            /*
            $('.show_password_checker').focus(function() {
                $('.password-checker').show();
            });

            $('.show_password_checker').focusout(function(){
                $('.password-checker').hide();
            });
            */

            $("a#memberLoginLink").attr("href", memLoginPath);
            $('.modal').find('#memberLoginLink').on('click',function (e) {
                e.preventDefault();
                window.location = memLoginPath;
            });


            /**
             * CLOSE MODALS
             */
            $('.modal').find('.close,.btn-done').on('click',function () {
                var path = docPath.page + '?modal='+modalName+'/closed';
                var dpath = $(this).data('path');
                var modalCloseEvent = 192;

                if(dpath && dpath !== null && dpath !== '') {
                    path = dpath;
                }

                if (tradeloop.pageCTA !== null) {
                    path = path + '&' + ctaKey + '=' + tradeloop.pageCTA;
                }

                switch(modalName){
                    case 'register/confirmation-message':
                        tradeloop.pageCTA = null;
                        break;
                    case 'login':
                        history.pushState({},"Tradeloop | The Wholesale Marketplace for Used Electronics",'/');
                        break;
                }

                if(path.indexOf('register') !== -1) {
                    path = path + '&funnel=register';
                }

                switch(modalName){
                    case 'help_request':
                        modalCloseEvent = 200;
                        break;
                    case 'account/cancel-subscription/confirm':
                    case 'account/cancel-subscription/discount':
                    case 'account/cancel-subscription/form':
                    case 'trial-membership/cancel/confirm':
                        modalCloseEvent = 296;
                        break;
                }

                window.Analytics.track('modal-closed',{
                    type_id: modalCloseEvent,
                    eventData: {
                        cta: tradeloop.pageCTA ? tradeloop.pageCTA : modalName,
                    },
                    localData: {
                        datavar: tradeloop.pageCTA ? tradeloop.pageCTA : modalName,
                    }
                });
                /*
                framework.post(url('api/log_generic_event'),{
                    _token: $('input[name="_token"]:first').val(),
                    'type_id': modalCloseEvent,
                    'datavar': tradeloop.pageCTA ? tradeloop.pageCTA : modalName,
                    'uri': docPath.page
                });
                */

                if(typeof closeModalTo != 'undefined'){
                    window.location = closeModalTo;
                }

                if(typeof pgFCF != 'undefined') {
                    window.location = '/join?cta=FCF';
                }

                tradeloop.escape_queue.pop();
                $('#modal-container').fadeOut(function(){
                    $('.main-body').removeClass('blur-in');
                    $(this).remove();
                });
                tradeloop.scrollUnlock('md-close-unlock-40000');
            });

            if (typeof onLoad == 'function') {
                onLoad();
            } else {
                window.Analytics.track('modal-opened',{
                    type_id: 191,
                    eventData: {
                        cta: tradeloop.pageCTA ? tradeloop.pageCTA : modalName,
                    },
                    localData: {
                        datavar: tradeloop.pageCTA ? tradeloop.pageCTA : modalName,
                    }
                });
            }

            if (typeof tradeloop[modalNameForInit] == 'function') {
                tradeloop[modalNameForInit]();
                console.debug('LOADED FUNCTION: '+modalNameForInit);
            } else {
                console.debug('NO LOAD FUNCTION FOR:'+id);
                console.debug('TRYING:'+modalNameForInit);
            }

            if(typeof modal_message_id !== 'undefined') {
                switch(modal_message_id){
                    case 7000:
                        modal_error_message = 'Please login again. We didn\'t recognize this computer.';
                        break;
                }
                if (modal_error_message !== null) {
                    tradeloop.show_modal_error(modal_error_message);
                }
            }

        });
    };


    /**
     * FREE TRIAL CANCEL FORM
     */
    exports.init_trial_membership_cancel_confirm = function(){
        $('#ftc-keep-benefits').click(function(e){
            tradeloop.doSimpleCloseModal(e, function(){
                window.Analytics.track('button-click-keep-my-benefits',{
                    type_id: 294,
                    eventData: {},
                    localData: {
                        uri: url('modal/trial-membership/cancel/confirm'),
                        datavar: 'keep-my-benefits-cancel-confirm',
                    }
                });
            });
        });

        $('#ftc-more-time').click(function(){
            var data = {
                "_token": $('input[name="_token"]:first').val(),
                "x-sender": "js",
                "ui": $('input#ui').val(),
                "cp": $('input#cp').val(),
                "en": $('input#en').val(),
            };

            if (typeof tlpxtoken != 'undefined') {
                data.tlpxtoken = tlpxtoken;
            }

            framework.post(url('modal/trial-membership/cancel/trial-extended/do-set'), data, function(r) {
                tradeloop.modal('trial-membership/cancel/trial-extended');
                return;
            }, function(e, data) {
                tradeloop.show_modal_error(data.error);
            });
        });
        
        $('#ftc-end-trial').click(function(){
            var data = {
                "_token": $('input[name="_token"]:first').val(),
                "x-sender": "js",
                "ui": $('input#ui').val(),
                "cp": $('input#cp').val(),
                "en": $('input#en').val(),
                "comments": $('#comments').val(),
            };

            if (typeof tlpxtoken != 'undefined') {
                data.tlpxtoken = tlpxtoken;
            }

            tradeloop.toggleMeEdits('hide_full_modal');
            
            framework.post(url('modal/trial-membership/cancel/do-cancel'), data, function(r) {
                tradeloop.modal('trial-membership/cancel/complete');
                return;
            }, function(e, data) {
                tradeloop.show_modal_error(data.error);
            });
        });
    };


    /**
     * VIEW CANCEL FORM
     */
    exports.init_account_cancel_subscription_confirm = function(){
        $('#btn-keep-my-benefits').click(function(e){
            tradeloop.doSimpleCloseModal(e, function(){
                window.Analytics.track('button-click-keep-my-benefits',{
                    type_id: 294,
                    eventData: {},
                    localData: {
                        uri: url('modal/account/cancel-subscription/confirm'),
                        datavar: 'keep-my-benefits-cancel-confirm',
                    }
                });
            });
        });

        $('#btn-remind-later').click(function(){
            var data = {
                "_token": $('input[name="_token"]:first').val(),
                "x-sender": "js",
                "ui": $('input#ui').val(),
                "cp": $('input#cp').val(),
                "en": $('input#en').val(),
            };

            if (typeof tlpxtoken != 'undefined') {
                data.tlpxtoken = tlpxtoken;
            }

            framework.post(url('modal/account/cancel-subscription/reminder/do-set'), data, function(r) {
                tradeloop.modal('account/cancel-subscription/reminder-set');
                return;
            }, function(e, data) {
                tradeloop.show_modal_error(data.error);
            });
        });
    };

    exports.init_account_cancel_subscription_discount = function(){
        $('#btn-keep-my-benefits').click(function(e){
            tradeloop.doSimpleCloseModal(e, function(){
                window.Analytics.track('button-click-keep-my-benefits',{
                    type_id: 294,
                    eventData: {},
                    localData: {
                        uri: url('modal/account/cancel-subscription/discount'),
                        datavar: 'keep-my-benefits-cancel-discount',
                    }
                });
            });
        });
        
        $('#btn-take-discount').click(function(){
            var data = {
                "_token": $('input[name="_token"]:first').val(),
                "x-sender": "js",
                "ui": $('input#ui').val(),
                "cp": $('input#cp').val(),
                "en": $('input#en').val(),
            };

            if (typeof tlpxtoken != 'undefined') {
                data.tlpxtoken = tlpxtoken;
            }

            framework.post(url('modal/account/cancel-subscription/discount/do-set'), data, function(r) {
                tradeloop.modal('account/cancel-subscription/discount-taken');
                return;
            }, function(e, data) {
                tradeloop.show_modal_error(data.error);
            });
        });
    };

    exports.init_account_cancel_subscription_form = function(){
        $('#btn-mind-change').click(function(e){
            tradeloop.doSimpleCloseModal(e, function(){
                window.Analytics.track('button-click-changed-my-mind',{ 
                    type_id: 295,
                    eventData: {},
                    localData: {
                        uri: url('modal/account/cancel-subscription/form'),
                        datavar: 'changed-my-mind-cancel-last-form',
                    }
                });
            });
        });
        
        $('#button_end_subscription').click(function(){
            if($('#form-cancel-account').valid()){
                var data = {
                    "_token": $('input[name="_token"]:first').val(),
                    "x-sender": "js",
                    "ui": $('input#ui').val(),
                    "cp": $('input#cp').val(),
                    "en": $('input#en').val(),
                    "comments": $('#comments').val(),
                    "reason": $('input[name="cancel_reason"]:checked').val(),
                };

                if (typeof tlpxtoken != 'undefined') {
                    data.tlpxtoken = tlpxtoken;
                }

                tradeloop.toggleMeEdits('hide_full_modal');

                framework.post(url('modal/account/cancel-subscription/do-cancel'), data, function(r) {
                    tradeloop.modal('account/cancel-subscription/complete');
                    return;

                }, function(e, data) {
                    tradeloop.toggleMeEdits('show_full_modal');
                    tradeloop.show_modal_error(data.error);
                });
            }
        });
    };

    exports.init_free_trial = function(){
        $('#trial-request-btn').click(function(){
            if($('#cat1_id').valid()){
                var data = {
                    "_token": $('input[name="_token"]:first').val(),
                    "x-sender": "js",
                    "cat1id": $('#cat1_id').val(),
                };

                if (typeof tlpxtoken != 'undefined') {
                    data.tlpxtoken = tlpxtoken;
                }

                tradeloop.toggleMeEdits('hide_full_modal');

                framework.post(url('modal/take-trial'), data, function(r) {
                    if(r.hasOwnProperty('redirect_uri') && r.redirect_uri.length) {
                        window.location = r.redirect_uri;
                        return;
                    } else {
                        tradeloop.modal('help_request');
                    }
                }, function(e, data) {
                    tradeloop.toggleMeEdits('show_full_modal');
                    tradeloop.show_modal_error(data.error);
                });
            }
        });
    };

    exports.init_api_verify_phone_send_code = function(){
        $('#phone-verify-code-btn').click(function(){

            if($('#form-verify-phone').valid()){

                var data = {
                    "_token": $('input[name="_token"]:first').val(),
                    "x-sender": "js",
                    "ui": $('input#ui').val(),
                    "en": $('input#en').val(),
                    "code": $('input#phone_verify_code').val(),
                    "phone": $('input#phone').val(),
                };

                if (typeof tlpxtoken != 'undefined') {
                    data.tlpxtoken = tlpxtoken;
                }

                tradeloop.toggleMeEdits('hide_full_modal');

                framework.post(url('modal/api/verify/phone_check_code'), data, function(r) {

                    window.location = tradeloop.doNextMsgPagePath('05600','&thanks=phone-verified');

                }, function(e, data) {
                    tradeloop.toggleMeEdits('show_full_modal');
                    tradeloop.show_modal_error(data.error);
                });
            }

        });
    };

    exports.init_display_name_user = function(){
        $('#save-btn').click(function(){
            if($('#form-display-name').valid()){

                var displayName = $('#contact-display-name').val();

                if(!tradeloop.check_display_name(displayName)) {
                    alert('Display Name does not meet the requirements.');
                    return;
                }

                var data = {
                    "_token": $('input[name="_token"]:first').val(),
                    "x-sender": "js",
                    "ui": $('input#ui').val(),
                    "cp": $('input#cp').val(),
                    "en": $('input#en').val(),
                    "display_name": displayName,
                };

                if (typeof tlpxtoken != 'undefined') {
                    data.tlpxtoken = tlpxtoken;
                }

                framework.post(url('modal/display_name/user/add'), data, function(r) {

                    if(typeof has_display_name !== 'undefined'){
                        has_display_name = true;
                    }
                    window.Analytics.track('added-display-name',{
                        type_id: 193,
                        eventData: {},
                        localData: {
                            datavar: 'display_name'
                        }
                    });

                    tradeloop.modal('display_name/user/thanks');
                    return;

                }, function(e, data) {
                    tradeloop.show_modal_error(data.error);
                });
            }

        });
    };

    exports.init_display_name_company = function(){
        $('#save-btn').click(function(){
            if($('#form-display-name').valid()){

                var displayName = $('#contact-display-name').val();

                if(!tradeloop.check_display_name(displayName)) {
                    alert('Display Name does not meet the requirements.');
                    return;
                }

                var data = {
                    "_token": $('input[name="_token"]:first').val(),
                    "x-sender": "js",
                    "ui": $('input#ui').val(),
                    "cp": $('input#cp').val(),
                    "en": $('input#en').val(),
                    "display_name": displayName,
                };

                if (typeof tlpxtoken != 'undefined') {
                    data.tlpxtoken = tlpxtoken;
                }

                framework.post(url('modal/display_name/company/add'), data, function(r) {

                    if(typeof has_cp_display_name !== 'undefined'){
                        has_cp_display_name = true;
                    }
                    window.Analytics.track('added-display-name',{
                        type_id: 193,
                        eventData: {},
                        localData: {
                            datavar: 'company_display_name'
                        }
                    });

                    tradeloop.modal('display_name/company/thanks');
                    return;

                }, function(e, data) {
                    tradeloop.show_modal_error(data.error);
                });
            }

        });
    };

    exports.init_buyer_approval_required = function(){
        $('#request_call').click(function(){
            var onPg = $baseurl + docPath.fullDocPath();

            tradeloop.toggleMeEdits('hide_full_modal');

            framework.post(url('api/request_phone_call'),{
                _token: $('input[name="_token"]:first').val(),
                fromPage: onPg
            }, function(r){
                window.Analytics.track('phone-call-requested',{
                    type_id: 193,
                    eventData: {},
                    localData: {
                        datavar: 'buyer_approval_required'
                    }
                });
                /*
                framework.post(url('api/log_generic_event'),{
                    _token: $('input[name="_token"]:first').val(),
                    'type_id':193,
                    'datavar':'buyer_approval_required',
                    'uri':docPath.page
                });
                */
                tradeloop.modal('buyer_approval_required_thanks');
                return;
            }, function(e, data){
                tradeloop.toggleMeEdits('show_full_modal');
                tradeloop.handleError(e,data,true);
            })


        });
    };

    exports.init_blocked_pending_checking = function(){
        $('#request_call').click(function(){
            var onPg = $baseurl + docPath.fullDocPath();

            tradeloop.toggleMeEdits('hide_full_modal');

            framework.post(url('api/request_blocked_call'),{
                _token: $('input[name="_token"]:first').val(),
                fromPage: onPg
            }, function(r){
                window.Analytics.track('request-blocked-call',{
                    type_id: 193,
                    eventData: {},
                    localData: {
                        datavar: 'blocked_pending_checking',
                    }
                });
                /*
                framework.post(url('api/log_generic_event'),{
                    _token: $('input[name="_token"]:first').val(),
                    'type_id':193,
                    'datavar':'blocked_pending_checking',
                    'uri':docPath.page
                });
                */
                tradeloop.modal('help_request_thanks');
                return;
            }, function(e, data){
                tradeloop.toggleMeEdits('show_full_modal');
                tradeloop.handleError(e,data,true);
            })


        });
    };

    exports.init_help_request = function(){

        tradeloop.sendScriptToHead('https://www.google.com/recaptcha/api.js?render=explicit');

        if(tradeloop.recaptchaWidgId == null) {
            //console.debug('RECAPCHA: loading recapcha id stuff');
            setTimeout(function() {
                tradeloop.loadReCapsToPage('tl_rcaps');
            }, 2000);
        }

        $('#how_contact').on('change', function() {
            var howc = $(this).val();

            $('.how_contact_details').hide();

            switch(howc){
                case 'email':
                    $('#hcd_email').show();
                    break;
                case 'phone':
                    $('#hcd_phone').show();
                    break;
            }
        });


        $('#help-request-btn').click(function(){
            var isInError = 0;
            var hpPage =    $baseurl + docPath.fullDocPath();
            var hpid =      $('#hp_id').val();
            var hpText =    $('#helptext-box').val();
            var howc =      $('#how_contact').val();
            var cemail =    $('#using_email').val();
            var cphone =    $('#using_phone').val();
            var uName =     $('#user_name').length > 0      ? $('#user_name').val()     : '';
            var cName =     $('#company_name').length > 0   ? $('#company_name').val()  : '';
            var reCapRt =   tradeloop.captchaResponse;

            if (hpid.length === 0) {
                tradeloop.show_modal_error("Please select a reason.");
                isInError = 1;
            }

            if (!isInError) {
                switch (howc) {
                    case 'email':
                        if (cemail.length === 0) {
                            tradeloop.show_modal_error("Please add your email address.");
                            isInError = 1;
                        }
                        break;
                    case 'phone':
                        if (cphone.length === 0) {
                            tradeloop.show_modal_error("Please add your phone number.");
                            isInError = 1;
                        }
                        break;
                }
            }

            if (!isInError) {
                if ($('#user_name').length > 0 && uName.length === 0) {
                    tradeloop.show_modal_error("Please tell us your name.");
                    isInError = 1;
                }
            }

            if (!isInError) {
                if (hpText.length === 0) {
                    tradeloop.show_modal_error("Please add your message below.");
                    isInError = 1;
                }
            }

            if (!isInError) {
                if (reCapRt.length === 0) {
                    tradeloop.show_modal_error("Are you a Robot?");
                    isInError = 1;
                }
            }

            if (!isInError) {
                tradeloop.toggleMeEdits('hide_full_modal');

                framework.post(url('api/send_help_request'), {
                    _token: $('input[name="_token"]:first').val(),
                    hpPage: hpPage,
                    hpid: hpid,
                    hpText: hpText,
                    howc: howc,
                    cemail: cemail,
                    cphone: cphone,
                    userName: uName,
                    companyName: cName,
                    reCapRt: reCapRt
                }, function(r) {
                    tradeloop.modal('help_request_thanks');
                    return;
                }, function(e, data) {
                    tradeloop.toggleMeEdits('show_full_modal');
                    tradeloop.handleError(e, data, true);
                });

            }

        });
    };

    exports.init_feedback_send = function(){
        $('#feedback-btn').click(function(){
            if($('#form-feedback').valid()){

                var fbPage = $baseurl + docPath.fullDocPath();
                var fbText = $('#feedback-box').val();
                var fbid = $('#fb_id').val();

                tradeloop.toggleMeEdits('hide_full_modal');

                framework.post(url('api/send_feedback'),{
                    _token: $('input[name="_token"]:first').val(),
                    feedbackFrom: fbPage,
                    feedbackText:  fbText,
                    feedbackId: fbid
                }, function(r){
                    window.Analytics.track('feedback-sent',{
                        type_id: 193,
                        eventData: {},
                        localData: {
                            datavar: 'feedback_send',
                        }
                    });
                    /*
                    framework.post(url('api/log_generic_event'),{
                        _token: $('input[name="_token"]:first').val(),
                        'type_id':193,
                        'datavar':'feedback_send',
                        'uri':docPath.page
                    });
                     */
                    tradeloop.modal('feedback_thanks');
                    return;
                }, function(e, data){
                    tradeloop.toggleMeEdits('show_full_modal');
                    tradeloop.handleError(e,data,true);
                })
            }

        });
    };

    exports.init_non_member_welcome = function(){
        window.Analytics.track('public-site-transition',{
            type_id: 58,
            eventData: {},
            localData: {
                uri: url('modal/public-site-transition'),
                datavar: 'public-site-transition',
            }
        });
        /*
		framework.post(url('api/log_generic_event'),{
	    	_token: $('input[name="_token"]:first').val(),
	    	'type_id':58,
	    	'datavar':'public-site-transition',
	    	'uri':url('modal/public-site-transition')
    	});
         */
    };

    exports.scrollToId = function (id,topOffSet) {
        var topId = $("#"+id).offset().top -topOffSet;
        $('html, body').animate({scrollTop:topId}, 200);
    };

    exports.scrollLock = function (lckFrom) {
        //console.debug('scrollLock:'+lckFrom);
        var scrollPosition = [
            self.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
            self.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        ];
        var html = $('html'); // it would make more sense to apply this to body, but IE7 won't have that
        html.data('scroll-position', scrollPosition);
        html.data('previous-overflow', html.css('overflow'));
        html.css('overflow', 'hidden');
        window.scrollTo(scrollPosition[0], scrollPosition[1]);
        tradeloop.scroll_locked = true;
    };

    exports.scrollUnlock = function (lckFrom) {
        //console.debug('scrollUnlock:'+lckFrom);
        if (tradeloop.do_not_unlock == true) {
            return;
        }
        var html = $('html');
        var scrollPosition = html.data('scroll-position');
        html.css('overflow', html.data('previous-overflow'));
        if(scrollPosition && scrollPosition.length > 0){
            window.scrollTo(scrollPosition[0], scrollPosition[1]);
        }
        tradeloop.scroll_locked = false;
        tradeloop.recaptchaWidgId = null;
    };

    exports.handleError = function(e, data,modal){
        //console.debug(e,data);
        if(typeof modal == 'undefined'){
            modal = false;
        }
        switch(e){
            case 'JsonParseError':

                break;
            case 'RequestFailed':
                if(modal){
                    tradeloop.show_modal_error(data.error);
                } else {
                    tradeloop.show_error(data.error);
                }

                break;
            case 'AjaxError':
                if(modal){
                    tradeloop.show_modal_error("An error occurred trying to complete request.");
                } else {
                    tradeloop.show_error("An error occurred trying to complete request.");
                }

                break;
            case 'ValidationFailed':
                if(modal){
                    tradeloop.show_modal_error(data.error);
                } else {
                    tradeloop.show_error(data.error);
                }
                break;
        }
    };

    exports.init_register_v1_old = function(){

        $('#register-email').keypress(function (e) {
            var code = (e.keyCode ? e.keyCode : e.which);
            if(code == 13) {
                $('.btn-register').click();
            }
            $('#emlSugg').remove();
        });


        $('.btn-register').click(function(){
            if($('#form-register').valid()){
                var path = docPath.page + '?modal=register/submitted';
                var usingEmail = $('#register-email').val();
                var buyingOrSelling = $('input[name="hme"]:checked').val();
                var whyBuyOrSell = $('input[name="shme"]:checked').val();
                var moreBuyOrSell = $('input[name="thme"]:checked').val();
                var regIntent = 'buy-and-sell';
                var sells_to_end_users = 0;
                var emlSugg = $('#emlSugg').val();
                var emlVals = 1;

                if(typeof emlSugg != 'undefined') {
                    emlVals = 0;
                }

                switch(buyingOrSelling){
                    case '100': // BUY
                    case '200': // SELL

                        switch(whyBuyOrSell){
                            case '1000':
                                regIntent = 'buy-for-my-own-use';
                                break;
                            case '2000':
                                regIntent = 'buy-to-resell';
                                break;
                            case '3000':
                                regIntent = 'sell-equipment-that-i-used';
                                break;
                            case '4000':
                                regIntent = 'sell-because-im-a-reseller';
                                break;
                        }

                        switch(moreBuyOrSell){
                            case '10000':
                            case '50000':
                                regIntent = regIntent + '-1-item';
                                break;
                            case '20000':
                            case '60000':
                                regIntent = regIntent + '-multiple-items';
                                break;
                            case '30000':
                            case '70000':
                                regIntent = regIntent + '-to-end-users';
                                sells_to_end_users = 1;
                                break;
                            case '40000':
                            case '80000':
                                regIntent = regIntent + '-to-other-resellers';
                                break;
                        }


                        break;
                }

                path = path + '&intent='+regIntent;
                if (tradeloop.pageCTA !== null) {
                    path = path + '&cta='+tradeloop.pageCTA+'&funnel=register';
                }

                window.Analytics.trackInGA('Register',{
                    cta: tradeloop.pageCTA
                });

                framework.post(url('api/register'),{
                    _token: $('input[name="_token"]:first').val(),
                    login: usingEmail,
                    top_daily_email: $('#top_daily_email').is(':checked')?1:0,
                    top_weekly_email: $('#top_weekly_email').is(':checked')?1:0,
                    reg_intent_string: regIntent,
                    buy_or_sell: buyingOrSelling,
                    buy_or_sell_why: whyBuyOrSell,
                    sells_to_end_users: sells_to_end_users,
                    emlVals: emlVals
                }, function(r){
                    //console.debug(r);
                    tradeloop.modal('register/enter_password',null,{
                        email: usingEmail
                    });
                }, function(e, data){

                    switch(data.errorCode){
                        case 910:
                            tradeloop.show_modal_error(data.error);
                            break;
                        case 920:
                            tradeloop.show_modal_error(data.error);
                            var emHtml = '<em class="invalid">Did you mean '+data.suggestedEmail+'</em><input type="hidden" id="emlSugg" name="emlSugg" value="yes">';
                            $('#emailAddressText').append(emHtml);
                            break;
                        default:
                            tradeloop.modal('login', function(){
                                $('#email').val(usingEmail);
                                tradeloop.show_modal_error(data.error);
                            },{},'signup-modal/err=email-exists');
                            break;
                    }

                });
            }
        });
    };

    exports.init_register_enter_password_v1_old = function(){
        $('.show-password').click(function(){
            var type = $(this).next('input').attr('type');
            if(type == 'password'){
                $(this).removeClass('fa-eye').addClass('fa-eye-slash');
                $(this).next('input').attr('type','text');
            } else {
                $(this).addClass('fa-eye').removeClass('fa-eye-slash');
                $(this).next('input').attr('type','password');
            }
        });

        $('#password').keyup(function(){
            var val = $(this).val();
            tradeloop.check_password(val);
        });

        var validator = $('#form-enter-password').validate();


        $('.btn-activate-account').click(function(){
            if($('#form-enter-password').valid()){

                var val = $('#password').val();
                var val2 = $('#password_confirm').val();
                if(!tradeloop.check_password(val)) {
                    var errors = { "password": "Password does not meet the requirements" };
                    validator.showErrors(errors);
                    return;
                }
                if(val !== val2) {
                    var errors = { "password": "Passwords do not match." };
                    validator.showErrors(errors);
                    return;
                }
                var path = docPath.page + '?modal=register/enter_password/submitted';
                if (tradeloop.pageCTA !== null) {
                    path = path + '&cta='+tradeloop.pageCTA+'&funnel=register';
                }

                window.Analytics.trackInGA('Register',{
                    cta: tradeloop.pageCTA
                });

                $('.btn-activate-account').addClass('hidden');
                $('.btn-loading-wait').removeClass('hidden');

                framework.post(url('api/create_password'),{
                    _token: $('input[name="_token"]:first').val(),
                    temporary_password: $('#temporary_password').val(),
                    password: $('#password').val(),
                    login: $('#login').val()
                }, function(r){
                    //window.location.reload();
                    if(typeof redirect_uri != 'undefined') {
                        window.location = redirect_uri;
                    } else {

                        var nextPagePath = docPath.fullDocPath() + docPath.nextParamSeparator + 'action=register-v/contact_info&carry='+tradeloop.pageCTA;
                        if(nextPagePath.substring(0,5) === '/join'){
                            nextPagePath = nextPagePath.replace('/join','/');
                        }
                        window.location = nextPagePath;
                        /*if(docPath.page == '/'){
                            //tradeloop.modal('are_in'); CORS problem with using modal
                            window.location = '/?action=are_in&cta=register';
                        } else {
                            window.location.reload();
                        }*/
                    }
                }, function(e, data){
                    //console.debug(e,data);
                    var path = docPath.page + '?modal=register/enter_password/error';
                    if (tradeloop.pageCTA !== null) {
                        path = path + '&cta='+tradeloop.pageCTA+'&funnel=register';
                    }

                    window.Analytics.trackInGA('Register',{
                        cta: tradeloop.pageCTA
                    });
                    tradeloop.handleError(e,data,true);
                    $('.btn-activate-account').removeClass('hidden');
                    $('.btn-loading-wait').addClass('hidden');
                });
            }
        });

        $('.btn-resend-code').click(function(){
            var btn = $(this).button('loading');
            var emlLogin = $(this).attr("useFor");
            var path = docPath.page + '?modal=register/enter_password/resend_code';
            if (tradeloop.pageCTA !== null) {
                path = path + '&cta='+tradeloop.pageCTA+'&funnel=register';
            }

            window.Analytics.trackInGA('Register',{
                cta: tradeloop.pageCTA
            });
            framework.post(url('api/resend_verification_code'),{
                _token: $('input[name="_token"]:first').val(),
                login: emlLogin,
                sendAccess: 1
            }, function(r){
                btn.button('reset');
                tradeloop.show_modal_message("Successfully resent email.");
            }, function(e, data){
                btn.button('reset');

                tradeloop.handleError(e,data,true);
            })
        });

    };

    exports.init_verify_email = function(){
        $('.show-password').click(function(){
            var type = $(this).next('input').attr('type');
            if(type == 'password'){
                $(this).removeClass('fa-eye').addClass('fa-eye-slash');
                $(this).next('input').attr('type','text');
            } else {
                $(this).addClass('fa-eye').removeClass('fa-eye-slash');
                $(this).next('input').attr('type','password');
            }
        });

        $('.btn-verify_email').click(function(){
            if($('#form-enter-password').valid()){
                var path = docPath.page + '?modal=verify_email/submitted';
                if (tradeloop.pageCTA !== null) {
                    path = path + '&cta='+tradeloop.pageCTA;
                }

                window.Analytics.trackInGA('VerifyEmail',{
                    cta: tradeloop.pageCTA
                });
                framework.post(url('api/verify_email'),{
                    _token: $('input[name="_token"]:first').val(),
                    verification_code: $('#verification_code').val(),
                    login: $('#login').val()
                }, function(r){
                    //tradeloop.modal('are_in')
                    window.location.reload();
                }, function(e, data){
                    var path = docPath.page + '?modal=verify_email/error';

                    window.Analytics.trackInGA('Error',{
                        action: 'verify-email'
                    });
                    tradeloop.handleError(e,data,true);
                });
            }
        });

        $('.btn-resend-code').click(function(){
            var btn = $(this).button('loading');
            var path = docPath.page + '?modal=verify_email/resend_code';
            if (tradeloop.pageCTA !== null) {
                path = path + '&cta='+tradeloop.pageCTA;
            }
            window.Analytics.trackInGA('VerifyEmail',{
                cta: tradeloop.pageCTA
            });

            framework.post(url('api/resend_verification_code'),{
                _token: $('input[name="_token"]:first').val(),
                login: $('#login').val()
            }, function(r){
                btn.button('reset');
                tradeloop.show_modal_message("Successfully resent email.");
            }, function(e, data){
                btn.button('reset');

                tradeloop.handleError(e,data,true);
            })
        });

    };

    exports.init_login_member = function(){

        $('.show-password').click(function(){
            var type = $(this).next('input').attr('type');
            if(type == 'password'){
                $(this).removeClass('fa-eye').addClass('fa-eye-slash');
                $(this).next('input').attr('type','text');
            } else {
                $(this).addClass('fa-eye').removeClass('fa-eye-slash');
                $(this).next('input').attr('type','password');
            }
        });


        $('#btn-login-member').click(function(){
            if($('#form-login-member').valid()){
                var cfSitePath = $baseurl.replace("www", "non-members");

                if(typeof redirect_uri != 'undefined' && redirect_uri != '') {
                    cfSitePath = redirect_uri;
                }

                var path = docPath.page + '?modal=login_member/submitted' ;

                window.Analytics.trackInGA('LoginMember',{});
                framework.post(url('api/login'),{
                    _token: $('input[name="_token"]:first').val(),
                    password: $('#password').val(),
                    login: $('#email').val(),
                    remember: $('#remember-me').is(':checked')?1:0
                }, function(r){
                    //console.debug(r);
                    /*
                    if(r.hasOwnProperty('needs_reset') && r.needs_reset == 1) {
                        tradeloop.modal('update_password');
                        return;
                    }
                    */
                    framework.post(url('api/log_event'),{
                        _token: $('input[name="_token"]:first').val(),
                        'type_id':107
                    }, function(){
                        window.location = cfSitePath;
                        return;
                    }, function(){
                        window.location = cfSitePath;
                        return;
                    });


                }, function(e, data){
                    framework.post(url('api/log_event'),{
                        _token: $('input[name="_token"]:first').val(),
                        'type_id':108
                    });
                    tradeloop.handleError(e,data,true);
                });
            }
        });
    };


    exports.init_login = function(){

        $('.show-password').click(function(){
            var type = $(this).next('input').attr('type');
            if(type == 'password'){
                $(this).removeClass('fa-eye').addClass('fa-eye-slash');
                $(this).next('input').attr('type','text');
            } else {
                $(this).addClass('fa-eye').removeClass('fa-eye-slash');
                $(this).next('input').attr('type','password');
            }
        });

        $('#password').keypress(function (e) {
            var code = (e.keyCode ? e.keyCode : e.which);
            if(code == 13) {
                $('.btn-login').click();
            }
        });

        $('.btn-login').click(function(){
            if($('#form-login').valid()){
                var path = docPath.page + '?modal=login/submitted';
                var separator = (window.location.href.indexOf("?")===-1)?"?":"&";
                var toLocation = window.location.href.replace(/\#/gi,'') + separator;
                var toRedirects = $('#to_redirects').val();
                var data = {
                    "_token":$('input[name="_token"]:first').val(),
                    "x-sender":"js",
                    "password":$('#password').val(),
                    "login":$('#email').val(),
                    "remember":$('#remember-me').is(':checked')?1:0
                };

                if(typeof toRedirects != 'undefined' && toRedirects != '') {
                    data.redirect_uri = toRedirects;
                }

                if (typeof tlpxtoken != 'undefined') {
                    data.tlpxtoken = tlpxtoken;
                }


                window.Analytics.trackInGA('Login',{});
                framework.post(url('dologins/login'), data, function(r){

                    //console.debug(r);

                    if(r.hasOwnProperty('redirect_uri') && r.redirect_uri.length) {
                        window.location = r.redirect_uri;
                        return;
                    }

                    if(r.hasOwnProperty('redirect') && r.redirect == 1) {
                        window.location = r.members_land;
                        //tradeloop.modal('member_only_login');
                        return;
                    }

                    if(typeof not_use_marketing_modal == 'undefined'){
                        if(r.hasOwnProperty('needs_reset') && r.needs_reset == 1) {
                            window.location = toLocation + "action=update_password";
                            return;
                        }

                        if(r.hasOwnProperty('show_splash') && r.show_splash == 1) {
                            window.location = toLocation + "action=public_content_gate_members_login";
                            return;
                        }
                    }

                    window.location.href = toLocation + "message=logged_in";
                    location.reload();


                }, function(e, data){
                    framework.post(url('api/log_event'),{
                        _token: $('input[name="_token"]:first').val(),
                        'type_id':108
                    });
                    tradeloop.handleError(e,data,true);
                });
            }
        });

    };

    exports.check_display_name = function(val){
        var dsp_name = val.trim();
        var dsp_name_lower = dsp_name.toLowerCase();
        var pattern = /[^a-zA-Z0-9_-]+/ig;
        var is_valid = true;

        if(dsp_name === '') {
            return false;
        }
        if(dsp_name.length < 4 || dsp_name.length > 15) {
            is_valid = false;
        }
        if(dsp_name_lower.includes("tradeloop")){
            is_valid = false;
        }
        if(dsp_name_lower.includes("admin")){
            is_valid = false;
        }
        if(pattern.test(dsp_name) === true) {
            is_valid = false;
        }
        return is_valid;
    }


    exports.check_password = function(val){
        var pattern = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9!@#$%^&*()+=,.<>?_-]+)$/;
        var letters = /^(.*[a-zA-Z]+.*)$/;
        var numbers = /^(.*[0-9]+.*)$/;
        if(val === '') {
            $('.password-test').addClass('failed').removeClass('success');
            return false;
        }
        $('.password-test').addClass('success').removeClass('failed');
        var is_valid = true;
        if(val.length < 8 || val.length > 24) {
            $('.length-test').addClass('failed').removeClass('success');
            is_valid = false;
        }

        if(letters.test(val) === false) {
            $('.letters-test').addClass('failed').removeClass('success');
            is_valid = false;
        }

        if(numbers.test(val) === false) {
            $('.numbers-test').addClass('failed').removeClass('success');
            is_valid = false;
        }

        if(pattern.test(val) === false) {
            $('.password-test').addClass('failed').removeClass('success');
            is_valid = false;
        }

        return is_valid;
    }

    exports.init_new_password = function(){
        $('.show-password').click(function(){
            var type = $(this).next('input').attr('type');
            if(type == 'password'){
                $(this).removeClass('fa-eye').addClass('fa-eye-slash');
                $(this).next('input').attr('type','text');
            } else {
                $(this).addClass('fa-eye').removeClass('fa-eye-slash');
                $(this).next('input').attr('type','password');
            }
        });

        $('#new-password').keyup(function(){
            var val = $(this).val();
            tradeloop.check_password(val);
        });

        var validator = $('#form-new-password').validate();

        $('.btn-reset-password').click(function(){
            if($('#form-new-password').valid()){
                var val = $('#new-password').val();
                var val2 = $('#password_confirm').val();
                if(val !== val2) {
                    var errors = { "new-password": "Password do not match." };
                    validator.showErrors(errors);
                    return;
                }
                if(!tradeloop.check_password(val)) {
                    var errors = { "new-password": "Password does not meet the requirements" };
                    validator.showErrors(errors);
                    return;
                }
                //var path = document.location.pathname + '/modal=new_password/submitted';
                var path = docPath.page + '?modal=new_password/submitted';
                if (tradeloop.pageCTA !== null) {
                    path = path + '&cta='+tradeloop.pageCTA;
                }

                window.Analytics.trackInGA('NewPassword',{
                    cta: tradeloop.pageCTA
                });
                framework.post(url('api/reset_password'),{
                    _token: $('input[name="_token"]:first').val(),
                    uid: $('#uid').val(),
                    password: $('#new-password').val()
                },function(r){
                    window.location = tradeloop.doNextMsgPagePath('05500');
                });
            }
        });


    };



    exports.init_reenter_password = function(){
        $('.show-password').click(function(){
            var type = $(this).next('input').attr('type');
            if(type == 'password'){
                $(this).removeClass('fa-eye').addClass('fa-eye-slash');
                $(this).next('input').attr('type','text');
            } else {
                $(this).addClass('fa-eye').removeClass('fa-eye-slash');
                $(this).next('input').attr('type','password');
            }
        });



    };



    exports.init_update_password = function(){
        $('.show-password').click(function(){
            var type = $(this).next('input').attr('type');
            if(type == 'password'){
                $(this).removeClass('fa-eye').addClass('fa-eye-slash');
                $(this).next('input').attr('type','text');
            } else {
                $(this).addClass('fa-eye').removeClass('fa-eye-slash');
                $(this).next('input').attr('type','password');
            }
        });

        $('#update-password').keyup(function(){
            var val = $(this).val();
            tradeloop.check_password(val);
        });

        var validator = $('#form-update-password').validate({
            rules: {
                "update-password": {
                    required: true,
                    minlength: 8
                },
                "confirm-update-password": {
                    equalTo: "#update-password"
                }
            },
            messages:{
                'confirm-update-password': {
                    equalTo: "Passwords do not match"
                }
            }
        });

        $('.btn-update-password').click(function(){

            if($('#form-update-password').valid()) {
                var val = $('#update-password').val();
                if(!tradeloop.check_password(val)) {
                    var errors = { "update-password": "Password does not meet the requirements" };
                    validator.showErrors(errors);
                    return;

                }

                var path = docPath.page + '?modal=new_password/submitted';

                window.Analytics.trackInGA('NewPassword',{});
                var uid = $('#uid').val();
                if(uid == '') {
                    if(typeof user != 'undefined' && typeof user.USERID !== 'undefined') {
                        uid = user.USERID;
                    }
                }
                framework.post(url('api/reset_password'),{
                    _token: $('input[name="_token"]:first').val(),
                    uid: uid,
                    password: $('#update-password').val()
                },function(r){
                    window.location = tradeloop.doNextMsgPagePath('05500');
                });
            }
        });


    };

    exports.init_due_diligence = function(){
        $('.btn-due-diligence').click(function(){
            tradeloop.show_message('Thanks! We will notify you as soon as the Due Diligence Reports are available.');
            $('.modal').find('.close,.btn-done').click();
        });
    };

    exports.show_message = function(message){
        //console.debug("Showing Messages in JS");
        if($('.message-container').length == 0){
            $('.wrapper').prepend('<div class="message-container"></div>');
        }
        if($('.message-container').find('.alert').length > 0){
            $('.message-container').find('.alert').remove();
        }
        var gid = guid();
        $('.message-container').append('<div id="'+gid+'" class="alert alert-success alert-color fade in"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button> <h3 class="text-center" style="margin: 0px !important;">'+message+'</h3></div>');
        $('#'+gid).on('closed.bs.alert', function () {
            $('.message-container').remove();
        });
    };

    exports.show_error = function(message){
        if($('.message-container').length == 0){
            $('.wrapper').prepend('<div class="message-container"></div>');
        }
        if($('.message-container').find('.alert').length > 0){
            $('.message-container').find('.alert').remove();
        }
        var gid = 'conff_1cont';
        $('.message-container').append('<div id="'+gid+'" class="alert alert-danger fade in"><button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button> <h3><strong class="tl-text--danger">Error:</strong> '+message+' </h3></div>');
        $('#'+gid).show();

        $('#'+gid).on('closed.bs.alert', function () {
            $('.message-container').remove();
        });

    };

    exports.show_modal_error = function(message){
        if($('.modal-message-container').length == 0){
            $('.modal-body').prepend('<div class="modal-message-container"></div>');
        }
        if($('.modal-message-container').find('.alert').length > 0){
            $('.modal-message-container').find('.alert').remove();
        }
        var gid = guid();
        $('.modal-message-container').prepend('<div id="'+gid+'" class="alert alert-danger fade in"> '+message+' </div>');

        $('#'+gid).on('closed.bs.alert', function () {
            $('.modal-message-container').remove();
        });



    };


    exports.show_modal_message = function(message){
        if($('.modal-message-container').length == 0){
            $('.modal-body').prepend('<div class="modal-message-container"></div>');
        }
        if($('.modal-message-container').find('.alert').length > 0){
            $('.modal-message-container').find('.alert').remove();
        }
        var gid = guid();
        $('.modal-message-container').prepend('<div id="'+gid+'" class="alert alert-success fade in">'+message+' </div>');
        //<button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
        $('#'+gid).on('closed.bs.alert', function () {
            $('.modal-message-container').remove();
        });

        setTimeout(function(){
            $('.modal-message-container').remove();
        }, 3000)

    };

    exports.logout = function(){

        var path = docPath.page + '?action=logged_out';

        window.Analytics.trackInGA('Logout',{});
        framework.post(url('dologins/logout'),{
            _token: $('input[name="_token"]:first').val()
        },function(r){

            //console.debug(r);
            window.location = url('?message=public_logged_out');
        });
    };

    exports.doClearPushState = function(){
        history.pushState(null, "", window.location.href.split('?')[0]);
    };

    exports.init_register_confirmation_message = function(){
        history.pushState({},"Tradeloop | The Wholesale Marketplace for Used Electronics",window.location.href.split('?')[0]);
    };


    exports.init_forgot_password = function(){
        /*
        $('#forgot-email').focusout(function(){
            var val = $(this).val();
            if(val == ""){
                $('.for').html('');
            } else {
                $('.for').html(' for <a href="mailto:'+val+'">'+val+'</a>');
            }
        });
        */


        $('.btn-forgot-password').click(function(){
            if($('#form-forgot-password').valid()) {
                //var path = document.location.pathname + '/modal=forgot_password/submitted';
                var path = docPath.page + '?modal=forgot_password/submitted';

                window.Analytics.trackInGA('ForgotPassword',{});
                var the_email = $('#forgot-email').val();

                framework.post(url('api/forgot_password'),{
                    _token: $('input[name="_token"]:first').val(),
                    login: $('#forgot-email').val()
                },function(r){

                    if(r.hasOwnProperty('redirect') && r.redirect == 1) {
                        tradeloop.modal('register/enter_password',null,{
                            email: r.email
                        });
                        return;
                    }

                    tradeloop.modal('password_reset', function(){
                        $('.the-email').html(the_email);

                        $('.btn-go-reset').click(function(){
                            if($('#form-reset-password').valid()){
                                framework.post(url('api/check_reset_code'),{
                                    _token: $('input[name="_token"]:first').val(),
                                    login: the_email,
                                    reset_code: $('#reset-code').val()
                                },function(r){
                                    tradeloop.modal('new_password',null,{
                                        user_id: r.uid
                                    });
                                }, function(e, data){
                                    //var path = '/modal=register/error';
                                    tradeloop.handleError(e,data,true);
                                });
                            }
                        });

                        $('.btn-resend-code').click(function(){
                            var btn = $(this).button('loading');
                            var path = docPath.page + '?modal=forgot_password/submitted';

                            window.Analytics.trackInGA('ForgotPassword',{});
                            framework.post(url('api/forgot_password'),{
                                _token: $('input[name="_token"]:first').val(),
                                login: the_email,
                                resend:1
                            }, function(r){
                                btn.button('reset');
                                tradeloop.show_modal_message("Successfully resent email.");
                            }, function(e, data){
                                btn.button('reset');
                                tradeloop.handleError(e,data,true);
                            })
                            //tradeloop.modal('forgot_password',null,{
                            //    email: the_email
                            //});
                        });

                    },{
                        email: $('#forgot-email').val()
                    });
                }, function(e,data){
                    //tradeloop.handleError(e,data,true);
                    tradeloop.modal('forgot_password',null,{
                        email: the_email
                    });
                    tradeloop.show_modal_message("Please try again.");
                });
            }
        });
    };

    exports.displayHelpers = () => {
       return {  
            disableButton: function(btn) {
                $(btn).addClass('tl-btn-dimmed disabled-link').prop('disabled', true);
                return this;
            },
            enableButton: function(btn) {
                $(btn).removeClass('tl-btn-dimmed disabled-link').removeAttr('disabled');
                return this;
            },
            setProcessingText: function(btn) {
                this.disableButton(btn).setText(btn, 'Processing... Please wait.');
                return this;
            },
            clearProcessingText: function(btn, txt = 'Submit') {
                this.enableButton(btn).setText(btn, txt);
                return this;
            },
            setText: function(inp, txt) {
                $(inp).val(txt);
                return this;
            },
            setElmText: function(inp, txt) {
                $(inp).text(txt);
                return this;
            },
            fadeInElm: function(elm) {
                $(elm).fadeIn();
                return this;
            },
            fadeOutElm: function(elm, speed) {
                $(elm).fadeOut(speed);
                return this;
            },
            hideElm: function(elm) {
                $(elm).hide();
                return this;
            },
            showElm: function(elm) {
                $(elm).show();
                return this;
            },
        }
    };


    exports.stateCountryDrops = function(stateWrapper = '#stateDropWrap', stateSelector = '#edit-states', countrySelector = '#edit-country') {

        this.cidusa = 211;
        this.cidcan = 36;
        this.stateWrapper = stateWrapper;
        this.stateSelector = stateSelector;
        this.countrySelector = countrySelector;

        this.showStatesFor = function(countryId){
            $(this.stateSelector + ' option[data-countryid]').prop('disabled',true);
            $(this.stateSelector + ' option[data-countryid]').css('display','none');

            $(this.stateSelector + ' option[data-countryid="'+countryId+'"]').prop('disabled',false);
            $(this.stateSelector + ' option[data-countryid="'+countryId+'"]').css('display','inline');
        };

        this.hideStateSelector = function() {
            $(this.stateSelector + ' option:selected').removeAttr('selected');
            $(this.stateSelector).removeAttr('required');
            $(this.stateWrapper).hide();
        };

        this.showStateSelector = function(countryId) {
            $(this.stateWrapper).show();
            this.showStatesFor(countryId);
            $(this.stateSelector).prop('required',true);
        };

        this.doCountryChange = function() {
            let ops = parseInt($(this.countrySelector + ' :selected').val());
            switch (ops) {
                case this.cidusa:
                case this.cidcan:
                    this.hideStateSelector();
                    this.showStateSelector(ops);
                    break;
                default:
                    this.hideStateSelector();
                    break;
            }
        };

        this.setDefaultCountryTo = function(countryText) {
            switch (countryText) {
                case 'United States':
                    this.showStateSelector(this.cidusa);
                    break;
                case 'Canada':
                    this.showStateSelector(this.cidcan);
                    break;
                default:
                    this.hideStateSelector();
                    break;
            }
        };

    };


    exports.init_register_contact_info = function(){

        let stCtDrops = new tradeloop.stateCountryDrops(undefined,'#contact-states','#contact-country');

        stCtDrops.hideStateSelector();

        $('#contact-country').change(function(){
            stCtDrops.doCountryChange();
        });

        $('.btn-save-contact').click(function(){

            $('#warning_div').addClass('hidden');
            $('#loading').hide();
            $('#red_crossimg').hide();
            $('#check_img').hide();

            if($('#form-contact-info').valid()){

                isSiteOnline("https://"+document.getElementById('contact-website').value,function(result){
                    //alert(result);
                    var msg = result ? "Site is online" : "Site is offline";

                    if(msg == "Site is online"){
                        $('#loading').hide();
                        $('#red_crossimg').hide();
                        $('#check_img').show();
                        $("#check_img").html("<img src='/images/icons/check_green.png' height='16px;' width='16px;'>");

                        $('#website_domain_valid').val("1");

                        var path = docPath.page + '?modal=register-v/contact_info/submitted';
                        var displayName = $('#contact-display-name').val();

                        if(!tradeloop.check_display_name(displayName)) {
                            alert('Display Name does not meet the requirements.');
                            return;
                        }

                        if (tradeloop.pageCTA !== null) {
                            path = path + '&cta='+tradeloop.pageCTA+'&funnel=register';
                        }

                        tradeloop.toggleMeEdits('hide');

                        window.Analytics.trackInGA('Register',{
                            cta: tradeloop.pageCTA
                        });
                        framework.post(url('api/contact_info'),{
                            _token: $('input[name="_token"]:first').val(),
                            fname: $('#contact-fname').val(),
                            lname: $('#contact-lname').val(),
                            display_name: $('#contact-display-name').val(),
                            company: $('#contact-company').val(),
                            webpage: $('#contact-website').val(),
                            country: $('#contact-country').val(),
                            phone: $('#contact-phone').val(),
                            address: $('#contact-address').val(),
                            address2: $('#contact-address2').val(),
                            city: $('#contact-city').val(),
                            state: $('#contact-states').val(),
                            zip: $('#contact-zip').val()
                        },function(r){

                            if(typeof has_Contact_Information !== 'undefined'){
                                has_Contact_Information = true;
                            }

                            if(typeof has_display_name !== 'undefined'){
                                has_display_name = true;
                            }

                            if(r.hasOwnProperty('blocked') && r.blocked == 1) {
                                window.location.reload();
                                return;
                            }

                            if(typeof not_use_marketing_modal == 'undefined'){
                                tradeloop.doModalUppathOpen({
                                    cta: 'public-register-confirmation-message',
                                    onLoad: null
                                });
                                return;
                            } else {

                                var nextPage = docPath;

                                nextPage.removeQStringParam('action');
                                nextPage.removeQStringParam('carry');

                                //window.location = nextPage.fullDocPath() + nextPage.nextParamSeparator + 'message=contact-info-added';
                                window.location = nextPage.fullDocPath();

                            }

                        }, function(e,data){
                            tradeloop.handleError(e,data,true);
                            tradeloop.toggleMeEdits('show');
                        });

                    } //if(msg == "Site is online"){

                    else
                    {
                        $('#loading').hide();
                        $('#check_img').hide();
                        $('#warning_div').removeClass('hidden');
                        $('#red_crossimg').show();
                        $("#red_crossimg").html("<img src='/images/icons/cross_red.gif' height='16px;' width='16px;'>");

                        $('#website_domain_valid').val("0");
                    } //else if(msg == "Site is online"){

                });//isSiteOnline("http://"+document.getElementById('contact-website').value,function(result){

            }
        });


    };



    exports.init_saved_searches = function(){
        tradeloop.dispatchGetSearchesEvent();

        $('.btn-save-search').click(function(){
            tradeloop.modal('save_this_search');
        });

    };

    exports.init_email_subscriptions = function(){
        _my_account.get_searches();

    };

    exports.init_quick_response = function(){
        $('.qr').click(function(){
            $('#reply-to-listing').val($('.qr:checked').val());
            $('#reply-to-listing').focus();
        });


    };

    exports.init_save_this_search = function(){


        if(typeof _show_member_listings !== 'undefined' && _show_member_listings) {
            $('.adv-alert').removeClass('hidden');
        }

        if(_data.filters.hasOwnProperty('categories') && _data.filters.categories.length == 1) {
            $('#save-search-name').val('My '+_data.filters.categories[0].name+' Search');
        } else if (_data.filters.hasOwnProperty('manufacturer_name') && _data.filters.manufacturer_name && _data.filters.manufacturer_name != '') {
            $('#save-search-name').val('My '+_data.filters.manufacturer_name+' Search');
        } else if (_data.filters.hasOwnProperty('search')) {
            $('#save-search-name').val('My '+_data.filters.search+' Search');
        } else {
            $('#save-search-name').val('My New Search');
        }

        if(_data.filters.hasOwnProperty('categories') && _data.filters.categories ||
            _data.filters.hasOwnProperty('manufacturer_ids') && _data.filters.manufacturer_ids
        ){
            $('#email-checkbox').prop('disabled','');
            //$('.email-radio').find('input').prop('disabled','');
            //$('.email-radio').find('input').prop('checked','checked');
            //$('.email-radio-caption').html("Yes, send me new listings.");
            $('.btn-save-the-search').html('Save Search & Email');
        } else {
            $('#email-checkbox').prop('disabled','disabled');
            //$('.no-email-radio').find('input').prop('checked','checked');
            //$('.email-radio').find('input').prop('disabled','disabled');
            //$('.email-radio-caption').html('Select a category or manufacturer to email this search.');
            $('.btn-save-the-search').html('Save Search - Online Only');
        }

        $('#email-checkbox').click(function(){
            if($(this).is(':checked')) {
                $('.btn-save-the-search').html('Save Search & Email');
            } else {
                $('.btn-save-the-search').html('Save Search - Online Only');
            }
        });

        $('.btn-save-the-search').click(function(){

            if($('#form-save-search').valid()){
                //var path = document.location.pathname + '/modal=save_this_search/submitted';
                var path = docPath.page + '?modal=save_this_search/submitted';

                window.Analytics.trackInGA('SaveSearch',{});
                var data = {
                    _token: $('input[name="_token"]:first').val(),
                    filter_name: $('#save-search-name').val(),
                    email_schedule: $('#email-checkbox').is(':checked')?2:0,
                    //is_home_view: $('#is_home_view').is(':checked')?1:0,
                    filters: _data.filters
                };

                if($(this).attr('data-id') != 0){
                    data['id'] = $(this).attr('data-id')
                }

                framework.post(url('api/save_search'),data, function(r){
                    //console.debug(r);
                    tradeloop.show_message('New Filter Created!');

                    $('.modal .close').click();
                    _data.filters.selected_search = r.search;
                    _data.filters.selected_search.name = r.search.filter_name;

                    document.dispatchEvent(new CustomEvent('filter.populateSavedSearches', {detail: {sd: false,k: true}}));
                }, function(e, data){

                });
            }
        });
    };


    exports.dispatchGetSearchesEvent = function() {
        document.dispatchEvent(new CustomEvent('filter.getSearches', {}));
    };


    return exports;

})();

window._tl_drop_notifications = (function () {

    var exports = {};
    exports.nNav    = $('#notifications-nav');
    exports.nMenu   = $('#notification-drop-menu');
    exports.nTip    = $('#notification-tooltip');
    exports.lsTip   = 'show-header-notify-floodlight';

    exports.init = function () {

        try {
            const showNotifyTooltip = localStorage.getItem(this.lsTip);
            if (showNotifyTooltip !== '0') {
                if(typeof try_notify_tips != 'undefined') {
                    this.doOpenToolTipNotify();
                }
            }
        } catch (e) {}

        $("#notification-tooltip-got-it").click(function(){
            _tl_drop_notifications.doKillToolTipNotify();
        });

        $(".dm-inside-wrap a.nav-dm-links").click(function(){
            _tl_drop_notifications.doClosePersistTip();
        });

    };


    exports.doOpenToolTipNotify = function() {
        var thisNav     = this.nNav;
        var thisMenu    = this.nMenu;
        var thisTip     = this.nTip;

        this.setActiveMarker(thisMenu,true);
        this.setHiddenMarker(thisTip,false);

        $(thisMenu).fadeIn(2000, function() {
            _tl_drop_notifications.setOpenMarker(thisNav,true);
        });
    };

    exports.doKillToolTipNotify = function() {
        var thisMenu    = this.nMenu;
        var thisTip     = this.nTip;

        $(thisMenu).fadeOut(10, function() {
            _tl_drop_notifications.doCloseNotifyNav();
            _tl_drop_notifications.setActiveMarker(thisMenu,false);
            _tl_drop_notifications.setHiddenMarker(thisTip,true);
            _tl_drop_notifications.doClosePersistTip();
        });
    };

    exports.doCloseNotifyNav = function() {
        var thisNav     = this.nNav;
        var thisMenu    = this.nMenu;

        _tl_drop_notifications.removeStyleAtts(thisMenu);
        _tl_drop_notifications.setOpenMarker(thisNav,false);
    };

    exports.doClosePersistTip = function() {
        try {
            localStorage.setItem(this.lsTip, '0');
        } catch (e) {}
    };

    exports.removeStyleAtts = function(id) {
        $(id).removeAttr('style');
    };

    exports.setHiddenMarker = function(id,doAdd) {
        this.setMeClasses(id,doAdd,'hidden');
    };

    exports.setActiveMarker = function(id,doAdd) {
        this.setMeClasses(id,doAdd,'activated');
    };

    exports.setOpenMarker = function(id,doAdd) {
        this.setMeClasses(id,doAdd,'open');
    };

    exports.setMeClasses = function(id,doAdd,doClass) {
        if(doAdd){
            $(id).addClass(doClass);
        } else {
            $(id).removeClass(doClass);
        }
    };


    return exports;

})();

window.check_website = function() {

    $('#warning_div').addClass('hidden');
    $('#loading').hide();
    $('#red_crossimg').hide();
    $('#check_img').hide();

    var website_name = $('#contact-website').val();

    if(website_name != ""){
        isSiteOnline("https://"+document.getElementById('contact-website').value,function(result){
            var msg = result ? "Site is online" : "Site is offline";

            if(msg == "Site is online"){
                $('#loading').hide();
                $('#red_crossimg').hide();
                $('#check_img').show();
                $("#check_img").html("<img src='/images/icons/check_green.png' height='16px;' width='16px;'>");
                $('#website_domain_valid').val("1");
            }
            else{
                $('#loading').hide();
                $('#check_img').hide();
                $('#warning_div').removeClass('hidden');
                $('#red_crossimg').show();
                $("#red_crossimg").html("<img src='/images/icons/cross_red.gif' height='16px;' width='16px;'>");
                $('#website_domain_valid').val("0");
            }
        })
    }
}

Array.prototype.remove = function(v) { this.splice(this.indexOf(v) == -1 ? this.length : this.indexOf(v), 1); }

Array.prototype.getUnique = function(){
    var u = {}, a = [];
    for(var i = 0, l = this.length; i < l; ++i){
        if(u.hasOwnProperty(this[i])) {
            continue;
        }
        a.push(this[i]);
        u[this[i]] = 1;
    }
    return a;
}

$(document).ready(() => {

    App.init();

    tradeloop.init();
    _tl_drop_notifications.init();

    ColorMode.init();
    GoogleTranslateExt.init();

    $('img.scale-img-50').jScale({ls:'50px'});
    $('img.scale-img-100').jScale({ls:'100px'});
    $('img.scale-img-w100').jScale({w: '100px'});
    $('img.scale-img-120').jScale({ls:'120px'});
    $('img.scale-img-130').jScale({ls:'130px'});
    $('img.scale-img-175').jScale({ls:'175px'});

    $("img.tl-user-img-member").resizeAndCrop({width: 100, height: 100, contClass: 'tl-user-account-rd-img-wrap tl-rd-bordered'});
});
